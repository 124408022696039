
export class People {
    peoples: Array<peopleIn>;

    constructor() {
        this.peoples = [];
    }

    calcPayment(i) {
    }
}



export interface peopleIn {
    entryId: number;
    userId: string;
    subJobType: string;
    discount: number;
    charge: number;
    hours: number;
    payment: number;
    isOverride: boolean;
    jobStage:string;
    note:string,
    dueDate:Date;
    isDefault?:boolean;
    allowDropDowns?:boolean;
    isSameJob:boolean;
    
}
