export class JobOverview {
    allJobCount: number;
    allJobs: Array<Object>;
    hotJobs: Array<Object>;


    constructor() {
        this.allJobs = [];
        this.allJobCount = 0;
        this.hotJobs = [];
    }
}
