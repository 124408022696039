export class Dashboard {
    allJobCount: number;
    allJobs: Array<Object>;
    hotJobs: Array<Object>;
    totalJobs: number;
    totalEarning: number;
    totalPaid: number;
    balanceOwn: number;
    avgJob: number;

    constructor() {
        this.allJobs = [];
        this.allJobCount = 0;
        this.hotJobs = [];
        this.totalJobs = 0;
        this.totalEarning = 0;
        this.totalPaid = 0;
        this.balanceOwn = 0;
        this.avgJob = 0;
    }
}
