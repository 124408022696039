import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from './../../environments/environment';
@Injectable()
export class CustomHeader implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        let date = new Date();
        if (currentUser) {
            request = request.clone({
                setHeaders: {
                    versionNo: '1.0.1',
                    timeZoneOffset : date.getTimezoneOffset().toString()
                }
            });

        }
        return next.handle(request);
    }
}
