import { MasterData } from './master-data';
import { formatDate } from '@angular/common';

export class WorkOrder {
    masterData: MasterData;
    projectId: number;
    insurerId: number;
    dentGrid: DentGrid;
    dentGridId: number;
    poNumber: string;
    claimNumber: string;
    deductible: number;
    startDate: string = new Date().toISOString();
    startTime: string = new Date().toISOString();
    startDateTime: Date;
    finishDate: string = new Date().toISOString();
    finishTime: string = new Date().toISOString();
    minDate: string = new Date().toISOString();
    endDateTime: Date;
    invoiceNote: string;
    privateNote: string;
    projectList: Array<any>;
    insurerList: Array<any>;
    dentGridList: Array<DentGrid>;
    subJobs: Array<subJobsUI>;
    inputSubJob: subJobsUI;
    subJobEntryId: number;
    subJobList: Array<any>;
    timeline: timelineUI;

    constructor() {
        this.masterData = new MasterData();
        this.projectId = null;
        this.insurerId = null;
        this.dentGridId = 200;
        this.projectList = [];
        this.insurerList = []
        this.dentGridList = this.masterData.dentGrids;
        this.timeline = {
            bookedForEstimate: {},
            bookedForRepair: {},
            created: {
                plannedDate: new Date()
            },
            jobDone: {},
            repairFinish: {},
            repairStart: {}
        }
        this.loadInsurer();
        this.loadProjects();
        this.changeDentGrid();

        this.startDateTime = new Date();
        this.endDateTime = new Date();
        let moreSevenDate = new Date().getDate() + 1;
        this.endDateTime.setDate(moreSevenDate);
        this.subJobEntryId = 0;
        this.subJobs = [];
        this.subJobList = this.masterData.subJobs;
        this.generateDefaultSubJobs();
    }

    chaangeEndDate() {
        this.finishDate = this.startDate;
    }

    loadProjects() {
        let projects = [
            {
                projectId: 1,
                projectName: '2- Kelleher Ford Floored'
            },
        ]
        this.projectList = projects;
    }

    loadInsurer() {
        let insurer = [
            {
                insurerId: 1,
                insurerName: 'Aviva'
            },
        ]
        this.insurerList = insurer;
    }

    changeDentGrid() {
        for (let j = 0; j < this.dentGridList.length; j++) {
            if (this.dentGridList[j].DentGridId == this.dentGridId) {
                this.dentGrid = this.dentGridList[j];

            }
        }
        sessionStorage.setItem('dentGrid', JSON.stringify(this.dentGrid));
    }

    concatStartDateTime() {
        var dateString = formatDate(new Date(this.startDate), 'yyyy-MM-ddT', 'en');



        if (this.startTime == undefined) {
            var time = new Date(this.startDate);
            var timeString = time.getHours() + ':' + time.getMinutes();

            var combined = (dateString + '' + timeString);
            this.startDateTime = new Date(combined);

        } else {
            var time = new Date(this.startTime);
            var timeString = time.getHours() + ':' + time.getMinutes();
            var combined = (dateString + '' + timeString);
            this.startDateTime = new Date(combined);
        }

    }

    concatEndDateTime() {
        var dateString = formatDate(new Date(this.finishDate), 'yyyy-MM-ddT', 'en')
        if (this.finishTime == undefined) {
            var time = new Date(this.finishDate);
            var timeString = time.getHours() + ':' + time.getMinutes();
            var combined = (dateString + '' + timeString);
            this.endDateTime = new Date(combined);

        } else {
            var time = new Date(this.finishTime);
            var timeString = time.getHours() + ':' + time.getMinutes();
            var combined = (dateString + '' + timeString);
            this.endDateTime = new Date(combined);
        }
    }

    generateDefaultSubJobs() {
        // console.log("Generate subJobs");
        // console.log(this.subJobList);
        this.subJobs = [];
        for (let job = 0; job < this.subJobList.length; job++) {
            if (this.subJobList[job].isDefault) {
                this.inputSubJob = {
                    entryId: ++this.subJobEntryId,
                    userId: null,
                    subJobType: this.subJobList[job].subJobType,
                    dueDate: null,
                    note: '',
                    payment:0,
                    hours:0,
                    discount:0,
                    charge:0,
                    isDefault: this.subJobList[job].isDefault,
                    jobStage: this.subJobList[job].jobStage,
                    allowDropDowns: this.subJobList[job].allowDropDowns,
                    isSameJob:false
                }
                this.subJobs.push(this.inputSubJob);
            }

        }
        // console.log(this.subJobs);

    }


}

export interface DentGrid {
    DentGridId: number,
    DentGridSid: string,
    DentGridName: string,
    DentGridDescription: string,
    DentGridRules: any,
    PanelCoverage: Array<any>,
    RemoveInstallCoverageItems: Array<any>,
}

export interface DetailUI {
    projectId: number;
    insurerId: number;
    dentGrid: DentGrid;
    dentGridId: number;
    poNumber: string;
    claimNumber: string;
}

export interface subJobsUI {
    entryId: number;
    userId: string;
    subJobType: string;
    dueDate: Date;
    note: string;
    isDefault?: boolean;
    jobStage: string;
    allowDropDowns: boolean;
    payment:number;
    hours:number,
    discount:number,
    charge:number,
    isSameJob:boolean;
}

export interface timelineUI {
    bookedForEstimate: any;
    bookedForRepair: any;
    created: any;
    jobDone: any;
    repairFinish: any;
    repairStart: any;
}



