import { MasterData } from './master-data';
import vinDecoder from 'vin-decode';

export class Vehicle {
  masterData: MasterData;
  vin: string;
  year: number;
  make: number;
  makeName: string;
  model: number;
  modelName: string;
  trimLevel: string;
  bodyType: string;
  bodyTypeExtra: string;
  isUsed: boolean;
  stockNumber: string;
  plate: string;
  odometer: number;
  color: string;
  attachment: Array<string>;
  ownerName: string;
  ownerEmail: string;
  ownerPhone: string;
  ownerAddress: string;
  makeList: Array<any>;
  models: Array<Model>;
  modelList: Array<Model>;
  bodyTypes: Array<BodyType>;
  bodySizes: Array<any>
  bodySize: string;
  bodyTypeObj: BodyType;
  panels: Array<Panel>;
  panelList: Array<Panel>;
  selctedPanel: Number;
  imgURL: any;
  isPdrOnly:boolean;
  isBodyShop: boolean;
  public imagePath;
  public message: string;



  constructor() {
    this.year = new Date().getFullYear();
    this.masterData = new MasterData();
    this.makeList = this.masterData.makes;
    this.modelList = [];
    this.models = this.masterData.models;
    this.bodyTypes = this.masterData.bodyTypes;
    this.bodySizes = this.masterData.bodySizes;
    this.bodyTypeExtra = null;
    this.bodyTypeObj = this.masterData.bodyTypes[4];
    this.bodyType = this.bodyTypeObj.BodyTypeId;
    this.bodySize = null;
    this.panels = this.masterData.panels;
    this.panelList = [];
    this.changeBodyType(this.bodyTypeObj.BodyTypeId);
    this.selctedPanel = 0;
    this.imgURL = "assets/img/profile.png";
    this.isPdrOnly = false;
    this.isBodyShop = false;
  }

  loadVin() {
    let vin = sessionStorage.getItem('vinNoForJob');
    if (vin != undefined && vin != 'null') {
      // sessionStorage.removeItem('vinNoForJob');

      this.vin = vin;
      let vinDecode = vinDecoder(this.vin).decode();

      if (vinDecode.year)
        this.year = vinDecode.year;
      if (vinDecode.serialNumber)
        this.stockNumber = vinDecode.serialNumber;
      if (vinDecode.manufacturer) {
        let make = vinDecode.manufacturer.split(" ");
        if (make) {
          let makeObj = this.makeList.find(i => i.MakeName == make[0]);
          if (makeObj) {
            this.make = makeObj.MakeId;
            this.loadModels();
          }
        }

      }
      console.log(vinDecode);
      sessionStorage.setItem('vinNoForJob', null);

    }
  }

  selectBodySize(sizeId) {
    if (this.bodySize == sizeId) {
      this.bodySize = null;
    } else {
      this.bodySize = sizeId;
    }
  }

  setSelectedPannel(pannelId) {
    if (pannelId == this.selctedPanel) {
      this.selctedPanel = 0;
    } else {
      this.selctedPanel = pannelId;
    }
  }

  loadModels() {
    this.modelList = [];

    for (let i = 0; i < this.models.length; i++) {
      if (this.models[i].MakeId == this.make) {
        this.modelList.push(this.models[i]);
      }
    }

  }
  loadBodyTypes() {
    let model = this.modelList.find(i => i.ModelId === this.model);
    if (model) {
      if (this.bodyTypeExtra != null) {
        this.changeBodyType(this.bodyTypeExtra);
        this.bodyTypeExtra = null;
      } else {
        this.changeBodyType(model.BodyTypeId);
      }

    }
  }

  changeBodyType(bodyType) {
    this.bodyType = bodyType;
    let existingPanels = this.panelList;
    this.panelList = [];

    this.bodyTypeObj = this.bodyTypes.find(i => i.BodyTypeId === this.bodyType);
    if (this.bodyTypeObj) {
      this.bodyType = this.bodyTypeObj.BodyTypeId;
      for (let i = 0; i < this.bodyTypeObj.PanelIdList.length; i++) {
        const pannel = this.getPannelObject(this.bodyTypeObj.PanelIdList[i]);
        const pannelExisting = existingPanels.find(i => i.PanelId === pannel.PanelId);
        if (pannelExisting) {
          this.panelList.push(pannelExisting);
        } else if (pannel) {
          pannel.coding = 'N/D';
          pannel.state = 'N_D'
          this.panelList.push(pannel);
        }
      }
    }
    // console.log(this.panelList);
  }


  getPannelObject(panelId) {
    return this.panels.find(i => i.PanelId === panelId);
  }

  preview(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }

}
export interface Model {
  MakeId: number,
  ModelId: number,
  ModelName: string,
  BodyTypeId: string
}
export interface BodyType {
  BodyTypeId: string,
  Name: string,
  Description: string,
  PanelIdList: Array<number>,
  fileName: string,
  iconName: string,
}
export interface Panel {
  PanelId: number,
  PanelName: string,
  PanelAbbreviation: string,
  InspectionOrder: number,
  coding: string,
  state: string
}



