import { MasterData } from './master-data';

export enum JobCompletionStatus {
    JOB_NOT_STARTED,
    JOB_IN_PROGRESS,
    JOB_COMPLETED
};


export class JobStage {
    masterData: MasterData;
    selectedJobStageIndex: number;
    selectedJobStageId: number;
    jobStages: Array<JobStageItem>;
    assignUsers: Array<Assignee>;
    subJobStatusList: Object;
    constructor() {
        this.selectedJobStageIndex = 0;
        this.selectedJobStageId = -1;
        this.jobStages = [];
        this.assignUsers = [];
        this.masterData = new MasterData();
        this.subJobStatusList = {
            'checkin': [68, 39, 40, 81],
            'disassembly': [69, 41, 42, 82],
            'pdr': [70, 43, 44, 47],
            'reassembly': [71, 45, 46, 83],
            'paintshop': [72, 48, 49, 84],
            'post_cr_qc': [93, 94, 95, 96],
            'randi': [50, 51],
            'partinstall': [73, 50, 51, 85],
            'cleanup': [75, 60, 61, 87],
            'qualitycontrol': [74, 57, 58, 86],
            'ppf': [76, 63, 52, 88],
            'post_ppf_qc': [101, 102, 103, 104],
            'mechanical': [77, 65, 54, 89],
            'calibration': [78, 66, 55, 90],
            'glass': [79, 64, 53, 91],
            'post_glass_qc': [97, 98, 99, 100],
            'interiorclean': [80, 67, 56, 92]

        }

    }

    addAssignUser(userObj) {
        let assignUserObj = {
            id: userObj.id,
            name: userObj.name,
            userType: userObj.userType,
            typeName: ''
        };
        //Lookup the type's name from master data
        const typeObj = this.masterData.roles.find(function (roleObj, index, obj) {
            return roleObj.RoleId == userObj.userType;
        });
        assignUserObj.typeName = typeObj ? typeObj.RoleName : '';
        this.assignUsers.push(assignUserObj);
    }

    addJobStage(jobStage) {
        //Job stage is complete if all subjobs inside it are complete
        //let completionStatus = 0;
        let currentSubJobIndex = 0;
        //let allComplete = true;

        //Selected job stage = last one sent by the backend
        this.selectedJobStageId = jobStage.id;

        //let currentSubJobPending = true;    //False when current subjob is determined
        let arrSubJobs = new Array<SubJob>();

        for (let i = 0; i < jobStage.subJobs.length; i++) {
            const thisSubJob = jobStage.subJobs[i];
            if (thisSubJob.stageType === 'checkin'){
                if (thisSubJob.progress == undefined) {
                    thisSubJob.progress = 2;
                    thisSubJob.statusId = 39;
                }
            }
            if (thisSubJob.subjobId == jobStage.currentSubjobId) {
                currentSubJobIndex = i;
            }
            //Find assignee Index 
            let userIndex = -1;
            if (thisSubJob.assigneeId && thisSubJob.assigneeId != "") {
                userIndex = this.assignUsers.findIndex(function (element: Assignee, index, obj) {
                    return element.id == thisSubJob.assigneeId;
                });
            }
            let dueDate = thisSubJob.dueOn;
            if (!dueDate) {
                dueDate = null;
            } else {
                dueDate = new Date(thisSubJob.dueOn);
            }
            let statusList = this.subJobStatusList[thisSubJob.subJobType];
            if (statusList) {
                let isCorrectStatus = statusList.find(i => i == thisSubJob.statusId);
                if (!isCorrectStatus) {
                    thisSubJob.statusId = statusList[0];
                }
            }


            if (thisSubJob.isActive) {
                arrSubJobs.push({
                    id: thisSubJob.id, subjobId: thisSubJob.subjobId, name: thisSubJob.name, assigneeName: thisSubJob.assignee, assignee: thisSubJob.assignee, assigneeId: thisSubJob.assigneeId,
                    dueOn: dueDate, assigneeIndex: userIndex, isModified: false, progressPct: thisSubJob.progressPct, entryId: thisSubJob.entryId, subJobType: thisSubJob.subJobType, statusId: thisSubJob.statusId, progress: thisSubJob.progress, note: thisSubJob.note,
                });
            }


            /*
            if (thisSubJob.completionStatus == JobCompletionStatus.JOB_IN_PROGRESS) {
                completionStatus = JobCompletionStatus.JOB_IN_PROGRESS;
                allComplete = false;
                currentSubJobPending = false;
            }
            else if (thisSubJob.completionStatus == JobCompletionStatus.JOB_NOT_STARTED) {
                allComplete = false;
            }*/
        }
        /*
        if (allComplete) {
            completionStatus = JobCompletionStatus.JOB_COMPLETED;
        }*/

        //Get part list
        let arrParts = new Array<Part>();
        jobStage.partlist.forEach(part => {
            arrParts.push({
                id: part.partId,
                name: part.name,
                status: part.status,
                orderedDate: part.orderedDate,
                deliveryDate: part.deliveryDate,
                orderedPlace: part.orderedPlace,
                confirmationNo: part.confirmationNo,
                isModified: false
            });
        });
        //Get comment list
        let arrComments = new Array<Comment>();
        let hasBlockingComment = false;
        jobStage.comments.forEach(comment => {
            if (comment.stageId == jobStage.stageId && comment.isBlockingComment) {
                hasBlockingComment = true;
            }
            arrComments.push({
                isBlockingComment: comment.isBlockingComment,
                text: comment.text,
                userId: comment.userId,
                userName: comment.userName,
                updatedAt: comment.updatedAt,
                commentId: comment.commentId,
                stageId: comment.stageId
            });
        });

        if (!jobStage.commonData) {
            jobStage.commonData = { isSendSMS: false };
        } else {
            jobStage.commonData.isSendSMS = false;
        }

        let nextStage = {
            dateTime: new Date(),
            assignee: null,
        }
        if (jobStage.nextStage) {
            nextStage = jobStage.nextStage
        }
        let statusName = '';
        if (arrSubJobs[currentSubJobIndex])
            statusName = arrSubJobs[currentSubJobIndex].name

        //TODO: set blocking comment count
        this.jobStages.push({
            id: jobStage.id,
            hasBlockingComment: hasBlockingComment,
            stageId: jobStage.stageId,
            name: jobStage.name, completionStatus: jobStage.completionStatus, statusName: statusName, createdAt: jobStage.createdAt,
            lastUpdatedTime: jobStage.lastUpdatedTime, blockingCommentCount: jobStage.blockingCommentCount, currentSubJobIndex: currentSubJobIndex, nextStage: nextStage,
            subJobList: arrSubJobs, comments: arrComments, partlist: arrParts,
            currentSubjobId: jobStage.currentSubjobId, isSubJob: jobStage.isSubJob, status: jobStage.status, assignee: jobStage.assignee, dateTime: jobStage.dateTime, commonData: jobStage.commonData
        });
    }


    selectJobStage(index) {
        this.selectedJobStageIndex = index;
        this.selectedJobStageId = this.jobStages[index].id;

    }
    getSelectedJobStage(): JobStageItem {
        return this.jobStages[this.selectedJobStageIndex];
    }
    getSubJobIndexById(subJobId) {
        for (let i = 0; i < this.getSelectedJobStage().subJobList.length; i++) {
            const thisSubJob = this.getSelectedJobStage().subJobList[i];
            if (thisSubJob.subjobId == subJobId) {
                return i;
            }
        }
        return -1;
    }
}

export interface JobStageItem {
    id: number;
    name: string;
    completionStatus: number;
    statusName: string;
    lastUpdatedTime: Date;
    createdAt: Date;
    blockingCommentCount: number;
    currentSubJobIndex: number;
    subJobList: Array<SubJob>;
    comments: Array<Comment>;
    partlist: Array<Part>;
    stageId: number;
    currentSubjobId: number;
    status: number;
    isSubJob: boolean;
    dateTime: Date;
    assignee: string;
    commonData: any;
    nextStage: any;
    hasBlockingComment: boolean;
}

export interface SubJob {
    subjobId: number;
    id: string;
    name: string;
    assignee: string;
    assigneeName: string;
    assigneeIndex: number;
    assigneeId: string;
    progressPct: number;
    dueOn: Date;
    isModified: boolean;
    entryId?: number;
    subJobType?: any;
    statusId?: any;
    progress?: any;
    note?: string;
    dueDate?: any;
}

export interface Comment {
    isBlockingComment: boolean;
    text: string;
    userId: string;
    userName: string;
    updatedAt: Date;
    commentId: number;
    stageId: number;
}

export interface Part {
    id: number;
    isModified: boolean;
    name: string;
    status: string;
    orderedDate?: Date,
    deliveryDate?: Date,
    orderedPlace?: string,
    confirmationNo?: string,
}

export interface Assignee {
    id: string;
    name: string;
    userType: number;
    typeName: string;
}

