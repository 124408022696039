export class Account {
    accountSummary: accountSummaryUI;

    constructor() {
        this.accountSummary = {
            totalPayables:0,
            totalReceivables:0,
            payables:[],
            receivables:[],
        }
    }
}

export interface accountSummaryUI {
    totalPayables: number,
    totalReceivables: number,
    payables: Array<any>,
    receivables: Array<any>,

}
