import { MasterData } from './master-data';

export class Project {

    projectDetails: projectDetailIn;
    customerList: Array<any>;
    insurerList:Array<any>;
    masterData:MasterData;
    dentGridList: Array<DentGridI>;
    dentGrid: Object;
    dentGridId: number;

    constructor() {
        this.masterData = new MasterData();
        this.customerList = [];
        this.insurerList = [];
        this.projectDetails= {
            userId: null,
            id: 0,
            name : null,
            customerId: 0,
            insurerId: null,
            policyNo: "",
            lossLocation: null,
            lossDate: new Date().toISOString(),
            inspectionStartDate: new Date().toISOString(),
            inspectionEndDate: new Date().toISOString(),
            dentGridId: 0,
            purchaseOrderNo: null,
            discountPct: 0,
            locationId:null,
            ownerLocationName:'',
        }
        this.dentGridList =  this.masterData.dentGrids;
        this.dentGridId = null;
        this.changeDentGrid();
    }
    changeDentGrid() {
        for (let j = 0; j < this.dentGridList.length; j++) {
            if (this.dentGridList[j].DentGridId == this.dentGridId) {
                this.dentGrid = this.dentGridList[j];

            }
        }
    }


}

export interface projectDetailIn {
    userId: string;
    id: number;
    name: string
    customerId: number;
    insurerId:string;
    policyNo: string;
    lossLocation: string;
    lossDate: string;
    inspectionStartDate: string;
    inspectionEndDate: string;
    dentGridId: number;
    purchaseOrderNo: string;
    discountPct: number;
    locationId:number;
    ownerLocationName:string;
}

export interface DentGridI {
    DentGridId: number,
    DentGridSid: string,
    DentGridName: string,
    DentGridDescription: string,
    DentGridRules: any,
    PanelCoverage: Array<any>,
    RemoveInstallCoverageItems: Array<any>,
}


