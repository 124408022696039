import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MasterData } from 'src/app/_models';
import { MasterDataService } from './master-data.service';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  @Output() getCurrentUser: EventEmitter<any> = new EventEmitter();
  masterDate: MasterData;
  authToken: string;
  constructor(
    private http: HttpClient,
    private masterdataService: MasterDataService,
    public plt: Platform
  ) {
    this.authToken = JSON.parse(localStorage.getItem('authToken'));
  }

  login(username: string, password: string) {
    username = username.toLowerCase();
    return this.http
      .post<any>(`${environment.apiUrl}/api/PDRMaxUsers/emailLogin`, {
        deviceType: 0,
        credentials: { email: username.toLowerCase(), password: password },
      })
      .pipe(
        map((authRes) => {
          // login successful if there's a jwt token in the response
          console.log(authRes);

          if (authRes.user && authRes.id) {
            console.log(`come`);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('authToken', JSON.stringify(authRes.id));

            if (
              !(
                authRes.user.userType == 2000 ||
                authRes.user.userType == 2100 ||
                authRes.user.userType == 3000 ||
                authRes.user.userType == 3100 ||
                authRes.user.userType == 1000 ||
                authRes.user.userType == 1100 ||
                authRes.user.userType == 500 ||
                authRes.user.userType == 700
              )
            ) {
              authRes.user.isProjectTabEnable = true;
            } else {
              authRes.user.isProjectTabEnable = false;
            }

            if (
              !(
                authRes.user.userType == 2000 ||
                authRes.user.userType == 2100 ||
                authRes.user.userType == 3000 ||
                authRes.user.userType == 3100 ||
                authRes.user.userType == 1000 ||
                authRes.user.userType == 1100
              )
            ) {
              authRes.user.isCreateJobEnable = true;
            } else {
              authRes.user.isCreateJobEnable = false;
            }

            localStorage.setItem('currentUser', JSON.stringify(authRes.user));
            localStorage.setItem('apikey', 'ABC');
            this.getCurrentUser.emit(authRes.user);
            this.masterdataService.getMasterData();
          }
          return authRes.user;
        })
      );
  }

  register(user) {
    return this.http
      .post<any>(`${environment.apiUrl}/api/AnalyticsWebUsers/signUp`, {
        user: user,
        isMetric: true,
        country: 'XX',
      })
      .pipe(
        map((authRes) => {
          // login successful if there's a jwt token in the response

          return authRes;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    this.uploadLogsByCleaning();

    // localStorage.removeItem('currentUser');
  }

  uploadLogsByCleaning() {
    let logs = JSON.parse(localStorage.getItem('logging'));
    this.authToken = JSON.parse(localStorage.getItem('authToken'));
    if (this.authToken && logs) {
      this.uploadLogs(logs).subscribe(
        (res) => {
          console.log(res);
          localStorage.clear();
          sessionStorage.clear();
          //localStorage.setItem("logging", JSON.stringify(logs));
        },
        (err) => {
          console.log(err);
          localStorage.clear();
          sessionStorage.clear();
          //localStorage.setItem("logging", JSON.stringify(logs));
        }
      );
    } else {
      localStorage.clear();
      sessionStorage.clear();

      //localStorage.setItem("logging", JSON.stringify(logs));
    }
  }

  uploadLogs(logs) {
    this.authToken = JSON.parse(localStorage.getItem('authToken'));
    let platform = navigator.userAgent;
    console.log(navigator.userAgent);
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/Logiles/submitLogs?access_token=${this.authToken}`,
        {
          deviceType: platform,
          records: logs,
        }
      )
      .pipe(
        map(
          (res) => {
            console.log('Sent log successfully');
            // invoice add
            return res;
          },
          (error) => {
            console.log(error);
            return error;
          }
        )
      );
  }

  validateOnGoingJobListToken(token: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/api/ECDChangedJobs/validateToken`, {
        token: token,
      })
      .pipe(
        map((authRes) => {
          return authRes;
        })
      );
  }
}
