export class Finance {

    totalJobs: number;
    totalEarnings: number;
    totalPaid: number;
    balanceOwn: number;
    accHistory: Array<any>;

    constructor() {
        this.totalJobs = 0;
        this.totalEarnings = 0;
        this.totalPaid = 0;
        this.balanceOwn = 0;
        this.accHistory = [];
    }


}
export interface adjustmentUI {
    adjustmentId: string;
    adjustmentDetails: string;
    subtotal: number;
    total: number;
    gst: number;
    gstPct:number;
    hst: number;
    hstPct: number;
    hstSubtotal: number;
    isNew: boolean;
    isEarn?:boolean;
}

export interface invoiceUI {
    invoiceId: string;
    fileName: string;
    isFileUploaded : boolean;
    isNew: boolean;
    invoiceNo: string;
    invoiceDetails: string;
    total: number;
    gst: number;
    gstPct: number;
    subtotal: number;
    hst: number;
    hstPct: number;
    hstSubtotal: number;
    status: number;
    notes: string;
}
