export class Customer {
    userId: string;
    discount: number;
    phoneNo: number;
    email: string;
    taxRegion: string;
    exempt: Object;
    customerList: Array<any>;  
    name?: string;

    constructor() {
        this.customerList = [];
        this.userId = '';
        this.discount = 0;
        this.phoneNo = null;
        this.email = '';
        this.taxRegion = '';
        this.exempt = {'GST': true, 'PST': true , 'HST': true };
    }

    changeTaxRegion(taxRegion) {
        this.taxRegion = taxRegion;
    }
    selectExempt(key) {
        if (this.exempt[key] == true) {
            this.exempt[key] = false;
        } else {
            this.exempt[key] = true;
        }
        
    }
}
